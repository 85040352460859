import React from 'react'
import Layout from "../components/layout"
import piazzaParty from '../images/piaza-party.png'
import dogberry from '../images/dogberry-day-2.png'
import SEO from '../components/seo'

export default function MayorWelcomes() {
    return (
        <Layout>
            <SEO title="Mayor welcomes Messina troops, citizens to party on the Piazza" image={piazzaParty} />
            <h1>
                Mayor welcomes Messina troops, citizens to party on the Piazza
            </h1>
            <img src={piazzaParty} />
            <p>Preparations are busily underway for tonight’s banquet honoring the newly returned soldiers. Hero, daughter of Messina Mayor Leonato and hostess of the event, bustled about with excitement this morning as she determined the placement of floral arrangements and finalized seating charts.</p> 

            <p>“It’s going to be such a romantic night,” Hero predicted.</p>

            <p>Around her, workers on ladders hung a canopy of twinkling lights that will emulate the night sky above the cherished Fountain of Orion at the center of the piazza.</p>

            <p>The event will feature lavish fare courtesy of local restauranteurs, live performances by the Messina Orchestra and dancing. The evening will end with a brilliant fireworks display.</p>
            
            <img className="dogberry-ad" src={dogberry} />
        </Layout>
    )
}
